<template>
    <ak-form-decorator
        :id="id"
        :title="title"
        :helpText="helpText"
        :error="error"
        :hasError="hasError"
        :required="required"
        :value="value"
        :locales="locales"
    >
        <span class="ak-form__prefix">
            {{prefix}}
        </span>
        <input
                type="text"
                v-model="currentValue"
                :placeholder="placeholder"
                :readonly="readOnly"
                class="ak-form__control ak-form__control--prefix"
                :class="{
                    'ak-form__control--has-value': currentValue,
                }"
                v-on="$currentListeners"
        >

        <slot name="popover"></slot>
    </ak-form-decorator>

</template>

<script>
import FormElementMixin from "../../../../vendor/multimedium/spine/resources/spine-appkit-vue/src/components/form/mixins/FormElementMixin";

export default {
    name: 'ak-price',
    props: {
        prefix: {
            type: String,
            default: '€'
        }
    },
    mixins: [FormElementMixin],
    data() {
        return {
            modifiedValue: 0,
        };
    },
    computed: {
        currentValue: {
            get() {
                return this.modifiedValue / 10000;
            },
            set(e) {
                e = e.replace(',', '.');

                this.modifiedValue = e * 10000;
                this.$emit('input', this.modifiedValue)
            }
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value * 10000)
        }
    },
    created() {
        this.modifiedValue = this.value;
    }
}
</script>
