<template>
    <div class="p-t-xs-20 p-b-xs-20">

        <div v-if="order && cart && receipt" class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="m-b-xs-20">
                        <ak-button
                                size="small"
                                @click="backToOrders"
                        >
                            <i class="ak-icon ak-button__icon">arrow_left</i>
                            {{ $t('Ga terug') }}
                        </ak-button>
                        <template v-if="order.isInvoice && !order.cancelled && order.paymentStatus !== 1">
                            <ak-button
                                    size="small"
                                    colorMode="danger"
                                    style="vertical-align: bottom;"
                                    @click="showConfirm = true"
                            >
                                {{ $t('Bestelling annulleren') }}
                            </ak-button>
                        </template>
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Opmerkingen') }}
                            </div>
<!--                            <a class="m-l-auto" href="#" @click.prevent="openCommentsForm">-->
<!--                                {{ $t('Opmerking toevoegen') }}-->
<!--                            </a>-->
                        </div>
                        <div
                            class="ak-panel__body ak-panel__body--small reading"
                            :class="{'muted': !order.comments}"
                        >
                            {{ order.comments ? order.comments : $t('Geen opmerkingen') }}
                        </div>
                    </div>
                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Bestelling') }}
                            </div>
                            <strong class="m-l-auto">{{ cart.code }}</strong>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <thead>
                            <tr class="ak-table-row">
                                <th class="ak-table-head">
                                    {{ $t('Artikel nummer	') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Artikel') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Aantal') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Eenheid') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Eenheidsprijs') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Prijs') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody class="ak-table-body">
                            <tr v-for="line in receipt.items" class="ak-table-row">
                                <td class="ak-table-column">{{ line.code }}
                                </td>
                                <td class="ak-table-column">
                                    <a v-if="line.shoppable.href"
                                       :href="line.shoppable.href"
                                       class="ak-link ak-link--uppercase"
                                       target="_blank"
                                       v-html="line.title" />
                                    <span v-else v-html="line.title" />
                                </td>
                                <td class="ak-table-column">{{ line.quantity }}
                                </td>
                                <td class="ak-table-column">{{ line.size }}
                                </td>
                                <td class="ak-table-column">{{ line.amount.formatted }}
                                </td>
                                <td class="ak-table-column">{{ line.total.formatted }}
                                </td>
                            </tr>
                            <tr v-for="line in receipt.lines" class="ak-table-row">
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column"></td>
                                <td class="ak-table-column text--right" v-html="line.title"></td>
                                <td class="ak-table-column">{{ line.amount.formatted }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="ak-panel m-b-xs-20  ak-panel--no-padding">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Betaling') }}
                            </div>
                        </div>
                        <table v-if="order.paymentStatus === 1" class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                            <tr class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ $t('Betaling ontvangen') }} <i>({{ $t('Bevestigd door: ') }} {{ order.paymentChangedBy }})</i>
                                </td>
                            </tr>
                            <tr v-if="inspector.paymentType" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ inspector.paymentType.title }}
                                </td>
                            </tr>
                            <tr v-if="order.paymentCardHolderName" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentCardHolderName }}
                                </td>
                            </tr>
                            <tr v-if="order.getPaymentCardNumber" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.getPaymentCardNumber }}
                                    <div v-if="order.paymentCardExpirationMonthAndYear">
                                        {{ $t('Vervaldatum') }} {{ order.paymentCardExpirationMonthAndYear }}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="order.paymentMessage" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentMessage }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table v-else class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                            <tr class="ak-table-row">
                                <td v-if="order.cancelled" class="ak-table-column">
                                    {{ $t('Bestelling geannuleerd') }}
                                </td>
                                <td v-else class="ak-table-column">
                                    {{ $t('Betaling openstaande') }}
                                </td>
                            </tr>
                            <tr v-if="order.paymentMessage" class="ak-table-row">
                                <td class="ak-table-column">
                                    {{ order.paymentMessage }}
                                </td>
                            </tr>
                            <tr class="ak-table-row">
                                <td class="ak-table-column">
                                    <ak-button @click="openOrderDefinitionForm('payment-form')">
                                        {{ $t('Markeren als betaald') }}
                                    </ak-button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="order.comments" class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Opmerkingen') }}
                            </div>
                        </div>
                        <div class="ak-panel__body">
                            {{ order.comments }}
                        </div>
                    </div>

                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Historiek')}}
                            </div>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <thead>
                            <tr class="ak-table-row">
                                <th class="ak-table-head">
                                    {{ $t('Datum') }}
                                </th>
                                <th class="ak-table-head">
                                    {{ $t('Gebeurtenis') }}
                                </th>
                            </tr>
                            </thead>
                            <tbody class="ak-table-body">
                            <tr v-if="order.dateCancelled" class="ak-table-row">
                                <td class="ak-table-column">{{ order.dateCancelled | datetime }}</td>
                                <td class="ak-table-column">
                                    {{ $t('Bestelling geannuleerd') }}
                                </td>
                            </tr>
                            <tr v-if="order.dateOfShipping" class="ak-table-row">
                                <td class="ak-table-column">{{ order.dateOfShipping | datetime }}</td>
                                <td class="ak-table-column">
                                    {{ $t('Bestelling verzonden') }}
                                </td>
                            </tr>
                            <tr v-if="order.paymentStatus === 1 && order.dateOfPayment" class="ak-table-row">
                                <td class="ak-table-column">{{ order.dateOfPayment | datetime }}</td>
                                <td class="ak-table-column">
                                    {{ $t('Betaling ontvangen via @type', {'@type': inspector.paymentType.title}) }}
                                </td>
                            </tr>
                            <tr v-if="inspector.isOrdered" class="ak-table-row">
                                <td class="ak-table-column">{{ order.date | datetime }}</td>
                                <td class="ak-table-column">
                                    {{ $t('Bestelling geplaatst') }}
                                </td>
                            </tr>
                            <tr class="ak-table-row">
                                <td class="ak-table-column">{{ cart.date | datetime }}</td>
                                <td class="ak-table-column">{{ $t('Creatie winkelmandje')}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
                <div class="col-lg-4">
                    <div class="ak-panel ak-panel--no-padding  m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Factuuradres') }}
                            </div>
                            <a href="#" class="m-l-auto" @click.prevent="openAddressForm">
                                <i class="ak-icon">edit</i>
                            </a>
                        </div>
                        <div class="ak-panel__body ak-panel__body--small reading">
                            <p>
                                <a v-if="order.account" href="#" @click.prevent="accountForm"><strong>{{ order.invoiceFirstName }}  {{ order.invoiceSurnames }}</strong></a>
                                <strong v-else>{{ order.invoiceFirstName }}  {{ order.invoiceSurnames }}</strong>
                                <br />
                                <strong v-if="order.invoiceCompanyName"> {{order.invoiceCompanyName }} </strong>
                            </p>
                            <p v-if="order.invoiceStreetAddress || order.invoiceStreetNumber || order.invoiceBoxNumber || order.invoicePostalCode || order.invoiceCity || order.invoiceCountryIso">
                                {{ order.invoiceStreetAddress }} {{order.invoiceStreetNumber }} {{order.invoiceBoxNumber }}<br>
                                {{ order.invoicePostalCode }} {{ order.invoiceCity }} {{order.invoiceCountryIso }}
                            </p>
                            <p>
                                {{ order.phone }}<br>
                                <a :href="'mailto:'+order.email">{{ order.email }}</a>
                            </p>
                            <p v-if="order.invoiceVat || order.invoiceCompanyNumber">
                                {{ order.invoiceCompanyNumber ? order.invoiceCompanyNumber : order.invoiceVat }}
                            </p>
                        </div>
                    </div>
                    <div class="ak-panel ak-panel--no-padding m-b-xs-20">
                        <div class="ak-panel__header ak-panel__header--small">
                            <div class="ak-panel__title">
                                {{ $t('Leveringsgegevens') }}
                            </div>
                            <strong class="m-l-auto">
                                    <span v-if="order.shippingTypeId === 1">
                                        {{ $t('Afhalen') }}
                                    </span>
                                <span v-else>
                                        {{ $t('Leveren') }}
                                    </span>
                            </strong>
                        </div>
                        <table class="ak-table ak-panel__table">
                            <tbody class="ak-table-body">
                                <tr v-if="order.shippingFirstName" class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('Voornaam:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        {{ order.shippingFirstName }}
                                    </td>
                                </tr>
                                <tr v-if="order.shippingSurnames" class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('Achternaam:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        {{ order.shippingSurnames }}
                                    </td>
                                </tr>
                                <tr v-if="order.shippingName" class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('Naam:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        {{ order.shippingName }}
                                    </td>
                                </tr>
                                <tr v-if="order.shippingReceiverName" class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('Ontvanger naam:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        {{ order.shippingReceiverName }}
                                    </td>
                                </tr>
                                <tr v-if="order.shippingEmail" class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('E-mailadres:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        <a :href="'mailto:' + order.shippingEmail">
                                            {{ order.shippingEmail }}
                                        </a>
                                    </td>
                                </tr>
                                <tr v-if="order.shippingStreetAddress || order.shippingStreetNumber || order.shippingBoxNumber || order.shippingPostalCode || order.shippingCity || order.shippingCountryIso"
                                    class="ak-table-row">
                                    <td class="ak-table-column">
                                        <strong>{{ $t('Adres:') }}</strong>
                                    </td>
                                    <td class="ak-table-column">
                                        {{ order.shippingStreetAddress }} {{ order.shippingStreetNumber }}
                                        {{ order.shippingBoxNumber }},
                                        {{ order.shippingPostalCode }} {{ order.shippingCity }}
                                        {{ order.shippingCountryIso }}
                                    </td>
                                </tr>
                                <tr v-if="!order.cancelled && order.paymentStatus === 1 && order.shippingTypeId === 1">
                                    <td class="ak-table-column" colspan="2">
                                        <ak-button
                                            v-if="!order.pickupMarkedDate"
                                            @click="markPickup">
                                            {{ $t('Bestelling staat klaar voor afhaling') }}
                                        </ak-button>
                                        <span v-else>
                                            {{ $t('Bestelling is al gemarkeerd voor afhaling') }}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        <router-view
            name="childview"
            @orderSaved="orderSaved"
        />

        <ak-confirm
                v-model="showConfirm"
                :confirmTitle="$t('Ben je zeker?')"
                :confirmText="$t('Ben je zeker dat je deze bestelling wil annuleren?')"
                :confirmLabel="$t('Ja')"
                :cancelLabel="$t('Nee')"
                @confirm="cancelOrder"
                @cancel="showConfirm = false"
        />
    </div>

</template>

<script>
export default {
    name: 'OrderOverview',
    data() {
        return {
            order: null,
            cart: null,
            inspector: null,
            receipt: null,
            loading: false,
            showConfirm: false,
        }
    },
    methods: {
        async getOrder() {
            this.loading = true;
            const result = await this.$get('/order-overview/' + this.$route.params.orderCode);
            this.loading = false;

            if (result.success) {
                this.order = result.data.order;
                this.cart = result.data.cart;
                this.receipt = result.data.receipt;
                this.inspector = result.data.inspector;
            }
        },
        backToOrders() {
            this.$router.push({
                name: 'grid',
                params: {bundle: this.$route.params.bundle, definition: this.$route.params.gridDefinition}
            });
        },
        orderSaved() {
            this.getOrder();
            this.$router.push({name: 'order-overview'});
        },
        openOrderDefinitionForm(definition) {
            this.$router.push({
                name: 'order-overview-form',
                params: {
                    bundle: 'order',
                    orderCode: this.cart.code,
                    definition: definition,
                    objectId: this.order.hashId
                }
            });
        },
        openCommentsForm() {
            this.$router.push({
                name: 'order-overview-form',
                params:{
                    bundle: 'order',
                    orderCode: this.cart.code,
                    definition: 'comments-form',
                    objectId: this.order.hashId}
            });
        },
        openAddressForm() {
            this.$router.push({
                name: 'order-overview-form',
                params:{
                    bundle: 'order',
                    orderCode: this.cart.code,
                    definition: 'address-form',
                    objectId: this.order.hashId}
            });
        },
        accountForm() {
            if ( ! this.order.account) {
                return;
            }

            this.$router.push({
                name: 'order-overview-form',
                params: {
                    bundle: 'user',
                    definition: 'form',
                    objectId: this.order.account.hashId
                }
            });
        },
        async cancelOrder() {
            this.showConfirm = false;
            this.loading = true;
            const result = await this.$post('/order-cancel/' + this.$route.params.orderCode);
            this.loading = false;

            if (result.success) {
                await this.$router.go();
            }
        },
        async markPickup() {
            this.showConfirm = false;
            this.loading = true;
            const result = await this.$post('/order-mark-pickup/' + this.$route.params.orderCode);
            this.loading = false;

            if (result.success) {
                await this.$router.go();
            }
        }
    },
    created() {
        this.getOrder();
    },
}
</script>
