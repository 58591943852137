var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      placeholder: _vm.$t("Status selecteren"),
      options: _vm.selectOptions,
      reduce: _vm.reduce,
    },
    on: { input: _vm.valueChanged },
    model: {
      value: _vm.currentValue,
      callback: function ($$v) {
        _vm.currentValue = $$v
      },
      expression: "currentValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }