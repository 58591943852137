import App from './views/App.vue';
import router from './routes';

import AkApprovalStatusSelect from "./widgets/AkApprovalStatusSelect.vue";
import AkPrice from "./widgets/AkPrice.vue";

Vue.component('ak-approval-status-select', AkApprovalStatusSelect);
Vue.component('ak-price', AkPrice);

let app = new Vue({
        data() { 
            return {
                globalMessages: []
            }
        },
        router,
        render: h => h(App),
}).$mount('#app')

AppKit.init(app);
