import VueRouter from 'vue-router';
import OrderOverview from './views/pages/OrderOverview.vue';
import FormPage from "./views/pages/FormPage.vue";

Vue.use(VueRouter);

let routes = [
    {
        path: '/form/:bundle/:definition/:objectId?/:relationString?',
        name: 'formPage',
        component: FormPage,
        meta: {
            title: "Form"
        },
    },
];

let routesAppended = Vue.cmsRouter.getRoutesAppend();
const gridRouteIndex = routesAppended.findIndex(route => route.name === 'grid' );

routesAppended.splice(gridRouteIndex, 0,
    {
        path: '/:gridDefinition/:bundle/order-overview/:orderCode',
        name: 'order-overview',
        component: OrderOverview,
        meta: {
            title: "Order overview",
        },
        children: [
            {
                path: ':bundle/:definition/:objectId/:relationString?',
                name: 'order-overview-form',
                components:{
                    childview: {
                        template: '<cms-form v-on="$listeners"/>',
                    }
                },
            }
        ]
    })

routes = [...Vue.cmsRouter.getRoutesPrepend(), ...routes, ...routesAppended];

const router = new VueRouter({
    routes
});

Vue.cmsRouter.setActiveRouter(router);

export default router;
