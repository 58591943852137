<template>
    <ak-wrapper :class="{'ak-wrapper--no-header': $authService && !$authService.isAuthenticated()}">
        <ak-header
            v-if="$authService.isAuthenticated() && userInterface"
            v-model="showDrawer"
            :drawerPinned="drawerPinned"
        >
            <template v-slot:header-left>
                <cms-active-workspace/>
              <div v-if="$getSetting('isStaging')" class="ak-chip ak-chip--small ak-chip--outlined ak-chip--warning">
                {{ $t('Testomgeving')}}
              </div>
              <div v-else-if="! $getSetting('isLocal') && ! $getSetting('isStaging')" class="ak-chip ak-chip--small ak-chip--outlined ak-chip--info">
                {{ $t('Productie')}}
              </div>
              <div v-else class="ak-chip ak-chip--small ak-chip--outlined ak-chip--success">
                {{ $t('Lokaal')}}
              </div>
            </template>

            <template v-slot:header-right>
                <cms-settings-menu />
                <cms-account-menu />
            </template>
        </ak-header>

        <ak-drawer
            :logoPath="$getSetting('assets.logoDrawer')"
            :showDrawer="showDrawer"
            @close="showDrawer = false"
            @togglePinned="drawerPinned = $event"
        >
            <ak-menu v-if="userInterface"
                 :items="userInterface.menu"
                 :searchEnabled="true"
                 @menuItemClicked=" !drawerPinned ? showDrawer = false : null"
            >
            </ak-menu>
        </ak-drawer>

        <div class="ak-content"
            :class="{'ak-content--pinned': drawerPinned && $authService && $authService.isAuthenticated()}"
            v-if="!$authService.isAuthenticated() || $authService.isAuthenticated() &&  userInterface"
        >
            <router-view></router-view>
        </div>

        <ak-loader size="large" :loading="loading"/>

    </ak-wrapper>
</template>

<script>
    export default {
        name: 'app',
        data() {
            return {
                loadingState: false,
                showDrawer: false,
                drawerPinned: false,
                userInterface: null,
                loading: false,
            }
        },
        async created() {
            this.loading = true;

            // if we are logged in we need to get the userInterface
            if(await this.$authService.getIsAuthenticated()) {
                this.userInterface = await this.$userInterfaceHandler.updateUserInterface();
            }

            this.loading = false;
        }
    }
</script>

