var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ak-form-decorator",
    {
      attrs: {
        id: _vm.id,
        title: _vm.title,
        helpText: _vm.helpText,
        error: _vm.error,
        hasError: _vm.hasError,
        required: _vm.required,
        value: _vm.value,
        locales: _vm.locales,
      },
    },
    [
      _c("span", { staticClass: "ak-form__prefix" }, [
        _vm._v("\n        " + _vm._s(_vm.prefix) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "input",
        _vm._g(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.currentValue,
                expression: "currentValue",
              },
            ],
            staticClass: "ak-form__control ak-form__control--prefix",
            class: {
              "ak-form__control--has-value": _vm.currentValue,
            },
            attrs: {
              type: "text",
              placeholder: _vm.placeholder,
              readonly: _vm.readOnly,
            },
            domProps: { value: _vm.currentValue },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.currentValue = $event.target.value
              },
            },
          },
          _vm.$currentListeners
        )
      ),
      _vm._v(" "),
      _vm._t("popover"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }