var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "p-t-xs-20 p-b-xs-20" },
    [
      _vm.order && _vm.cart && _vm.receipt
        ? _c("div", { staticClass: "container-fluid" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c(
                  "div",
                  { staticClass: "m-b-xs-20" },
                  [
                    _c(
                      "ak-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.backToOrders },
                      },
                      [
                        _c("i", { staticClass: "ak-icon ak-button__icon" }, [
                          _vm._v("arrow_left"),
                        ]),
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t("Ga terug")) +
                            "\n                        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.order.isInvoice &&
                    !_vm.order.cancelled &&
                    _vm.order.paymentStatus !== 1
                      ? [
                          _c(
                            "ak-button",
                            {
                              staticStyle: { "vertical-align": "bottom" },
                              attrs: { size: "small", colorMode: "danger" },
                              on: {
                                click: function ($event) {
                                  _vm.showConfirm = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("Bestelling annulleren")) +
                                  "\n                            "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-8" }, [
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Opmerkingen")) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ak-panel__body ak-panel__body--small reading",
                        class: { muted: !_vm.order.comments },
                      },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(
                              _vm.order.comments
                                ? _vm.order.comments
                                : _vm.$t("Geen opmerkingen")
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Bestelling")) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", { staticClass: "m-l-auto" }, [
                          _vm._v(_vm._s(_vm.cart.code)),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Artikel nummer	")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Artikel")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Aantal")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Eenheid")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Eenheidsprijs")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Prijs")) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        { staticClass: "ak-table-body" },
                        [
                          _vm._l(_vm.receipt.items, function (line) {
                            return _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(line.code) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                line.shoppable.href
                                  ? _c("a", {
                                      staticClass: "ak-link ak-link--uppercase",
                                      attrs: {
                                        href: line.shoppable.href,
                                        target: "_blank",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(line.title),
                                      },
                                    })
                                  : _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(line.title),
                                      },
                                    }),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(line.quantity) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(line.size) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(line.amount.formatted) +
                                    "\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(line.total.formatted) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.receipt.lines, function (line) {
                            return _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }),
                              _vm._v(" "),
                              _c("td", {
                                staticClass: "ak-table-column text--right",
                                domProps: { innerHTML: _vm._s(line.title) },
                              }),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(_vm._s(line.amount.formatted)),
                              ]),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel m-b-xs-20  ak-panel--no-padding" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Betaling")) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.order.paymentStatus === 1
                      ? _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("tbody", { staticClass: "ak-table-body" }, [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c("td", { staticClass: "ak-table-column" }, [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(_vm.$t("Betaling ontvangen")) +
                                      " "
                                  ),
                                  _c("i", [
                                    _vm._v(
                                      "(" +
                                        _vm._s(_vm.$t("Bevestigd door: ")) +
                                        " " +
                                        _vm._s(_vm.order.paymentChangedBy) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.inspector.paymentType
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.inspector.paymentType.title
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.paymentCardHolderName
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.order.paymentCardHolderName
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.getPaymentCardNumber
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.order.getPaymentCardNumber
                                            ) +
                                            "\n                                    "
                                        ),
                                        _vm.order
                                          .paymentCardExpirationMonthAndYear
                                          ? _c("div", [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.$t("Vervaldatum")
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.order
                                                      .paymentCardExpirationMonthAndYear
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.order.paymentMessage
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.order.paymentMessage) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        )
                      : _c(
                          "table",
                          { staticClass: "ak-table ak-panel__table" },
                          [
                            _c("tbody", { staticClass: "ak-table-body" }, [
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _vm.order.cancelled
                                  ? _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t("Bestelling geannuleerd")
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.$t("Betaling openstaande")
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                              ]),
                              _vm._v(" "),
                              _vm.order.paymentMessage
                                ? _c("tr", { staticClass: "ak-table-row" }, [
                                    _c(
                                      "td",
                                      { staticClass: "ak-table-column" },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(_vm.order.paymentMessage) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("tr", { staticClass: "ak-table-row" }, [
                                _c(
                                  "td",
                                  { staticClass: "ak-table-column" },
                                  [
                                    _c(
                                      "ak-button",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openOrderDefinitionForm(
                                              "payment-form"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                        " +
                                            _vm._s(
                                              _vm.$t("Markeren als betaald")
                                            ) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        ),
                  ]
                ),
                _vm._v(" "),
                _vm.order.comments
                  ? _c(
                      "div",
                      {
                        staticClass: "ak-panel ak-panel--no-padding m-b-xs-20",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ak-panel__header ak-panel__header--small",
                          },
                          [
                            _c("div", { staticClass: "ak-panel__title" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.$t("Opmerkingen")) +
                                  "\n                            "
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "ak-panel__body" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.order.comments) +
                              "\n                        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Historiek")) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("thead", [
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Datum")) +
                                "\n                                "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "ak-table-head" }, [
                            _vm._v(
                              "\n                                    " +
                                _vm._s(_vm.$t("Gebeurtenis")) +
                                "\n                                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tbody", { staticClass: "ak-table-body" }, [
                        _vm.order.dateCancelled
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateCancelled)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Bestelling geannuleerd")) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.dateOfShipping
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateOfShipping)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Bestelling verzonden")) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.paymentStatus === 1 && _vm.order.dateOfPayment
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("datetime")(_vm.order.dateOfPayment)
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(
                                      _vm.$t("Betaling ontvangen via @type", {
                                        "@type":
                                          _vm.inspector.paymentType.title,
                                      })
                                    ) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inspector.isOrdered
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  _vm._s(_vm._f("datetime")(_vm.order.date))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(_vm.$t("Bestelling geplaatst")) +
                                    "\n                                "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", { staticClass: "ak-table-row" }, [
                          _c("td", { staticClass: "ak-table-column" }, [
                            _vm._v(_vm._s(_vm._f("datetime")(_vm.cart.date))),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "ak-table-column" }, [
                            _vm._v(_vm._s(_vm.$t("Creatie winkelmandje"))),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-4" }, [
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding  m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Factuuradres")) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "m-l-auto",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openAddressForm.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "ak-icon" }, [
                              _vm._v("edit"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ak-panel__body ak-panel__body--small reading",
                      },
                      [
                        _c("p", [
                          _vm.order.account
                            ? _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.accountForm.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.order.invoiceFirstName) +
                                        "  " +
                                        _vm._s(_vm.order.invoiceSurnames)
                                    ),
                                  ]),
                                ]
                              )
                            : _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.order.invoiceFirstName) +
                                    "  " +
                                    _vm._s(_vm.order.invoiceSurnames)
                                ),
                              ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _vm.order.invoiceCompanyName
                            ? _c("strong", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.order.invoiceCompanyName) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.order.invoiceStreetAddress ||
                        _vm.order.invoiceStreetNumber ||
                        _vm.order.invoiceBoxNumber ||
                        _vm.order.invoicePostalCode ||
                        _vm.order.invoiceCity ||
                        _vm.order.invoiceCountryIso
                          ? _c("p", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.order.invoiceStreetAddress) +
                                  " " +
                                  _vm._s(_vm.order.invoiceStreetNumber) +
                                  " " +
                                  _vm._s(_vm.order.invoiceBoxNumber)
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                                " +
                                  _vm._s(_vm.order.invoicePostalCode) +
                                  " " +
                                  _vm._s(_vm.order.invoiceCity) +
                                  " " +
                                  _vm._s(_vm.order.invoiceCountryIso) +
                                  "\n                            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.order.phone)
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "a",
                            { attrs: { href: "mailto:" + _vm.order.email } },
                            [_vm._v(_vm._s(_vm.order.email))]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.order.invoiceVat || _vm.order.invoiceCompanyNumber
                          ? _c("p", [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.order.invoiceCompanyNumber
                                      ? _vm.order.invoiceCompanyNumber
                                      : _vm.order.invoiceVat
                                  ) +
                                  "\n                            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ak-panel ak-panel--no-padding m-b-xs-20" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "ak-panel__header ak-panel__header--small",
                      },
                      [
                        _c("div", { staticClass: "ak-panel__title" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.$t("Leveringsgegevens")) +
                              "\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", { staticClass: "m-l-auto" }, [
                          _vm.order.shippingTypeId === 1
                            ? _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("Afhalen")) +
                                    "\n                                    "
                                ),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.$t("Leveren")) +
                                    "\n                                    "
                                ),
                              ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("table", { staticClass: "ak-table ak-panel__table" }, [
                      _c("tbody", { staticClass: "ak-table-body" }, [
                        _vm.order.shippingFirstName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Voornaam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.order.shippingFirstName) +
                                    "\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingSurnames
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Achternaam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.order.shippingSurnames) +
                                    "\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [_vm._v(_vm._s(_vm.$t("Naam:")))]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.order.shippingName) +
                                    "\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingReceiverName
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Ontvanger naam:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.order.shippingReceiverName) +
                                    "\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingEmail
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("E-mailadres:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "mailto:" + _vm.order.shippingEmail,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            " +
                                        _vm._s(_vm.order.shippingEmail) +
                                        "\n                                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.order.shippingStreetAddress ||
                        _vm.order.shippingStreetNumber ||
                        _vm.order.shippingBoxNumber ||
                        _vm.order.shippingPostalCode ||
                        _vm.order.shippingCity ||
                        _vm.order.shippingCountryIso
                          ? _c("tr", { staticClass: "ak-table-row" }, [
                              _c("td", { staticClass: "ak-table-column" }, [
                                _c("strong", [
                                  _vm._v(_vm._s(_vm.$t("Adres:"))),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "ak-table-column" }, [
                                _vm._v(
                                  "\n                                        " +
                                    _vm._s(_vm.order.shippingStreetAddress) +
                                    " " +
                                    _vm._s(_vm.order.shippingStreetNumber) +
                                    "\n                                        " +
                                    _vm._s(_vm.order.shippingBoxNumber) +
                                    ",\n                                        " +
                                    _vm._s(_vm.order.shippingPostalCode) +
                                    " " +
                                    _vm._s(_vm.order.shippingCity) +
                                    "\n                                        " +
                                    _vm._s(_vm.order.shippingCountryIso) +
                                    "\n                                    "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.order.cancelled &&
                        _vm.order.paymentStatus === 1 &&
                        _vm.order.shippingTypeId === 1
                          ? _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "ak-table-column",
                                  attrs: { colspan: "2" },
                                },
                                [
                                  !_vm.order.pickupMarkedDate
                                    ? _c(
                                        "ak-button",
                                        { on: { click: _vm.markPickup } },
                                        [
                                          _vm._v(
                                            "\n                                            " +
                                              _vm._s(
                                                _vm.$t(
                                                  "Bestelling staat klaar voor afhaling"
                                                )
                                              ) +
                                              "\n                                        "
                                          ),
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.$t(
                                                "Bestelling is al gemarkeerd voor afhaling"
                                              )
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        attrs: { name: "childview" },
        on: { orderSaved: _vm.orderSaved },
      }),
      _vm._v(" "),
      _c("ak-confirm", {
        attrs: {
          confirmTitle: _vm.$t("Ben je zeker?"),
          confirmText: _vm.$t(
            "Ben je zeker dat je deze bestelling wil annuleren?"
          ),
          confirmLabel: _vm.$t("Ja"),
          cancelLabel: _vm.$t("Nee"),
        },
        on: {
          confirm: _vm.cancelOrder,
          cancel: function ($event) {
            _vm.showConfirm = false
          },
        },
        model: {
          value: _vm.showConfirm,
          callback: function ($$v) {
            _vm.showConfirm = $$v
          },
          expression: "showConfirm",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }