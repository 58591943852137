<template>
    <v-select
        v-model="currentValue"
        :placeholder="$t('Status selecteren')"
        :options="selectOptions"
        :reduce="reduce"
        @input="valueChanged"
    />
</template>

<script>

export default {
    name: 'ak-approval-status-select',
    data() {
        return {
            currentValue: null
        }
    },
    props: {
        id: {
            type: String
        },
        value: {
            type: String
        },
        statuses: {
            type: Object|Array,
        }
    },
    computed: {
        selectOptions() {
            if (! this.statuses) {
                return []
            }

            return Object.keys(this.statuses).map(value => {
                return {
                    label: this.statuses[value],
                    value: value,
                }
            })
        }
    },
    methods: {
        valueChanged() {
            this.$emit('input', this.currentValue);
        },
        reduce(option) {
            return option.value;
        }
    },
    mounted() {
        this.currentValue = this.value;
    }
}
</script>
